<!--  -->
<template>
  <div class="index_cont">
    <div class="home_cont">
      <div class="home_head">
        <img class="bg_img" src="../../images/home/b1.png" />
        <div class="logo">
          <img src="../../images/home/logo.png" alt="" srcset="" />
        </div>
        <div class="head_tu">
          <img src="../../images/home/head.png" alt="" srcset="" />
        </div>
      </div>
      <div class="tishi">
        <div class="tishi_box" @click="tianFun">
          <div class="left_box">
            <img
              class="tu_box"
              src="../../images/home/tishi.png"
              alt=""
              srcset=""
            />
            <div class="text">2022 天府书展防疫须知健康提示！</div>
          </div>
          <van-icon name="arrow" size="12" color="#ED6C00" />
        </div>
      </div>
      <div class="lunbo">
        <van-swipe @change="onChange" :loop="true" :autoplay="3000">
          <van-swipe-item v-for="(itme, index) in lunbo_list" :key="index">
            <div class="lun_box">
              <div class="tu_box">
                <img
                  :src="'http://tfsz.symansbon.cn/' + itme.showImg"
                  alt=""
                  srcset=""
                />
              </div>
              <div class="title_box">
                <div class="time">
                  <span class="iconfont icon-rili"></span>
                  {{ itme.addTime }}
                </div>
                <div class="title">
                  {{ itme.tittle }}
                </div>
              </div>
            </div>
          </van-swipe-item>
          <template #indicator>
            <ul class="lun_tab">
              <li v-for="(itme, index) in lunbo_list" :key="index">
                <div class="dian" :class="current != index ? '' : 'heng'"></div>
              </li>
            </ul>
            <!-- <div class="custom-indicator">
            {{ current + 1 }}/{{ lunbo_list.length }}
          </div> -->
          </template>
        </van-swipe>
      </div>
      <div class="Menu_cont">
        <ul>
          <li v-for="itme in Menu_list" :key="itme.id">
            <div class="Menu_box" @click="menufun(itme.id)">
              <div class="tu_box">
                <img :src="itme.icon" alt="" />
              </div>
              <div class="title">
                {{ itme.title }}
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="Discounts_cont">
        <router-link to="/Discounts">
          <img
            class="tu_box"
            src="../../images/home/yuyue.png"
            alt=""
            srcset=""
          />
        </router-link>
        <router-link to="/robDiscount">
          <img
            class="tu_box"
            src="../../images/home/qiangjuan.png"
            alt=""
            srcset=""
          />
        </router-link>
      </div>
      <div class="Activity_cont">
        <div class="Activity_title">
          <div class="title">今日活动</div>
          <div class="more">更多</div>
        </div>
        <div class="huodong_cont">
          <div
            class="huodong_box"
            v-for="(item, index) in huodong_list"
            :key="index"
          >
            <div class="van-multi-ellipsis--l2 title">
              {{ item.tittle }}
            </div>
            <div class="time">
              {{ item.activityTime + item.activityTimeArea }}
            </div>
            <div class="didian">
              <span class="icon iconfont">&#xe61a;</span>{{ item.address }}
            </div>
          </div>
        </div>
      </div>
      <div class="Activity_cont">
        <div class="Activity_title">
          <div class="title">精选推荐</div>
          <div class="more">更多</div>
        </div>
        <div class="rukou_cont">
          <div class="left_box">
            <router-link to="/buyBooks?tab=0">
              <div class="rukou1">
                <img src="../../images/home/r1.png" alt="" />
              </div>
            </router-link>
          </div>
          <div class="right_box">
            <router-link to="/buyBooks?tab=1">
              <div class="rukou2">
                <img src="../../images/home/r2.png" alt="" />
              </div>
            </router-link>
            <router-link to="/buyBooks?tab=2">
              <div class="rukou">
                <img src="../../images/home/r3.png" alt="" />
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <!-- <div class="shousuo">
        <van-search
          v-model="shousuoValue"
          shape="round"
          background="#f8fbff"
          placeholder="请输入搜索关键词"
          @search="shousuo()"
        />
      </div> -->
    </div>
    <van-overlay :show="tianshow" @click="tianshow = false">
      <div class="wrapper">
        <div class="tan_box">
          <img src="../../images/home/tan.png" alt="" srcset="" />
        </div>
        <div class="off" @click="tianshow = false">
          <img
            class="off"
            src="http://tfbookfair2022.symansbon.cn/images/ins/off.png"
          />
        </div>
      </div>
    </van-overlay>
    <navBer :navNum="1"></navBer>
    <div class="kaiping" v-if="isKai == 1">
      <!-- <img src="../../images/home/yindao.png" alt="" srcset="" /> -->
      <img class="k1 d1" src="../../images/home/1.png" alt="" srcset="" />

      <img class="k3 d3" src="../../images/home/3.png" alt="" srcset="" />
      <div class="k2_box">
        <img class="k2 d2" src="../../images/home/2.png" alt="" srcset="" />
        <img class="jiantou d4" src="../../images/home/5.png" alt="" srcset="" />
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { Icon } from "vant";
import { Swipe, SwipeItem, Search, Overlay } from "vant";
import axios from "axios";
const url='https://tfbookfair2022.symansbon.cn';
export default {

  //import引入的组件需要注入到对象中才能使用
  components: {
    [Icon.name]: Icon,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Search.name]: Search,
    [Overlay.name]: Overlay,
  },
  data() {
    //这里存放数据
    return {
      isKai: 2,
      tianshow: false,
      current: 0,
      lunbo_list: [
        {
          imgUrl:
            "http://tfbookfair2022.symansbon.cn/images/home/202111181037343836.png",
          Time: "10.16 2021",
          title: "国内首套《元宇宙》图书亮相2021年天府...",
        },
        {
          imgUrl:
            "http://tfbookfair2022.symansbon.cn/images/home/202111181037343836.png",
          Time: "10.16 2021",
          title: "国内首套《元宇宙》图书亮相2021年天府...",
        },
        {
          imgUrl:
            "http://tfbookfair2022.symansbon.cn/images/home/202111181037343836.png",
          Time: "10.16 2021",
          title: "国内首套《元宇宙》图书亮相2021年天府...",
        },
        {
          imgUrl:
            "http://tfbookfair2022.symansbon.cn/images/home/202111181037343836.png",
          Time: "10.16 2021",
          title: "国内首套《元宇宙》图书亮相2021年天府...",
        },
      ],
      Menu_list: [
        {
          icon: "http://tfbookfair2022.symansbon.cn/images/home/n1.png",
          id: "1",
          title: "书展介绍",
        },
        {
          icon: "http://tfbookfair2022.symansbon.cn/images/home/n2.png",
          id: "2",
          title: "活动预告",
        },
        {
          icon: "http://tfbookfair2022.symansbon.cn/images/home/n3.png",
          id: "3",
          title: "实体展场",
        },
        {
          icon: "http://tfbookfair2022.symansbon.cn/images/home/n4.png",
          id: "4",
          title: "云展场",
        },
        {
          icon: "http://tfbookfair2022.symansbon.cn/images/home/n5.png",
          id: "5",
          title: "精选推荐",
        },
        {
          icon: "http://tfbookfair2022.symansbon.cn/images/home/n6.png",
          id: "6",
          title: "我要买书",
        },
      ],
      huodong_list: [
        {
          title: "点亮星空的人们，文轩姐姐讲红船故事",
          time: "11月16-20日 14:30-16:30",
          Site: "1号活动区",
          Link: "",
        },
        {
          title: "点亮星空的人们，文轩姐姐讲红船故事",
          time: "11月16-20日 14:30-16:30",
          Site: "1号活动区",
          Link: "",
        },
        {
          title: "点亮星空的人们，文轩姐姐讲红船故事",
          time: "11月16-20日 14:30-16:30",
          Site: "1号活动区",
          Link: "",
        },
      ],
      shousuoValue: "",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    onChange(index) {
      this.current = index;
    },
    shousuo() {
      //console.log(this.shousuoValue);
    },
    menufun(id) {
      //Ment菜单
      //console.log(id);
      switch (id) {
        case "1":
          this.$router.push("/Introduction");
          break;
        case "2":
          this.$router.push("/Activity");
          break;
        case "3":
          this.$router.push("/map");
          break;
          case "5":
          this.$router.push("/buyBooks");
          break;
        default:
          break;
      }
    },
    tianFun() {
      this.tianshow = true;
    },
    getinfo() {
      this.getRequest(
        `${url}/api/IndexSet`,
        {}
      ).then((res) => {
        //console.log(res);
        this.lunbo_list = res.news;
        this.huodong_list = res.activities;
      });
    },
    qingqiu() {
      this.getRequest(
        `${url}/Wx/get_accesstoken`,
        {}
      ).then((res) => {
        //console.log(res);
      });
      // axios
      //   .get("http://tfbookfairapi.symansbon.cn/Wx/get_accesstoken")
      //   .then(function (res) {
      //     //console.log(res.data);
      //   })
      //   .catch(function (err) {
      //     //console.log(err);
      //   });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    var that = this;
    //console.log(sessionStorage.getItem("kaiping") == "1");
    if (sessionStorage.getItem("kaiping") != "1") {
      sessionStorage.setItem("kaiping", "1");
      //console.log("打开开屏");
      setTimeout(function () {
        that.isKai = 0;
        //console.log("关闭开屏");
      }, 3500);
    } else {
      //console.log("跳过开屏");
      that.isKai = 0;
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getinfo();
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped lang="less">
.home_cont {
  overflow-x: auto;
  background: #f8fbff;
  -webkit-overflow-scrolling: touch;
  height: calc(100% - 60px);
  padding-bottom: 60px;
}
.home_head {
  position: relative;
  padding: 25px 27px 0 27px;
  background-position: bottom right;
  background-size: 250px 100%;
  background-repeat: no-repeat;
  overflow: hidden;
  .bg_img {
    position: absolute;
    width: 500px;
    left: 90px;
    top: -30%;
    bottom: 0;
  }
  .logo {
    margin-bottom: 42px;
    img {
      width: 185px;
    }
  }
  .head_tu {
    padding-bottom: 36px;
    img {
      width: 282.5px;
    }
  }
}
.tishi {
  position: relative;
  padding: 0 27px;
  display: flex;
  margin-bottom: 12px;
  .tishi_box {
    width: 100%;
    // height: 38px;
    background: #ffffff;
    box-shadow: 0px 5px 10px 1px rgba(42, 89, 159, 0.1);
    border-radius: 6px 6px 6px 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 25px;
    .left_box {
      display: flex;
      align-items: center;

      .tu_box {
        width: 30px;
        object-fit: contain;
        margin-right: 13.5px;
      }
      .text {
        height: 12px;
        font-size: 12px;
        font-weight: 400;
        color: #ed6c00;
      }
    }
  }
}
.lunbo {
  position: relative;
  // padding: 0 27px;

  .lun_box {
    position: relative;
    box-shadow: 0px 10px 20px 1px rgba(42, 89, 159, 0.1);
    border-radius: 12px 12px 12px 12px;
    margin-bottom: 24px;
    // width: 100%;
    .tu_box {
      img {
        width: 100%;
        object-fit: cover;
        border-radius: 12px 12px 0 0;
      }
    }
    .title_box {
      position: relative;
      background-color: #fff;
      // width: 100%;
      padding: 11px 14px 14px 14px;
      border-radius: 0 0 12px 12px;
      .time {
        margin-bottom: 19px;
        span {
          font-size: 14px;
        }
        font-size: 12px;

        font-weight: 400;
        color: #2a599f;
      }
      .title {
        font-size: 15px;
        font-weight: bold;
        color: #000000;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-bottom: 5px;
      }
    }
  }
  .lun_tab {
    display: flex;
    justify-content: center;
    li {
      .dian {
        width: 4px;
        height: 4px;
        background: #aaaaaa;
        margin-right: 9px;
        transition: all 0.4s linear;
      }
      .heng {
        width: 12px;
        height: 4px;
        background: #2a599f;
        border-radius: 7px 7px 7px 7px;
        margin-right: 9px;
      }
    }
  }
}
.Menu_cont {
  margin-top: 32px;
  padding: 0 27px;
  margin-bottom: 50px;
  ul {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    li {
      width: 90px;
      margin-right: 0px;
      margin-bottom: 20px;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
  .Menu_box {
    // width: 100%;
    text-align: center;
    .tu_box {
      img {
        width: 90px;
        height: 90px;
        // margin-bottom: 12px;
      }
    }
    .title {
      font-size: 12px;
      font-weight: 400;
      color: #000000;
    }
  }
}
.Discounts_cont {
  padding: 0 27px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  .tu_box {
    width: 154px;
    border-radius: 12px 12px 12px 12px;
  }
}
.Activity_cont {
  padding: 0 27px;
  margin-bottom: 32px;
  .Activity_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .title {
      font-size: 18px;
      font-weight: bold;
      color: #2a599f;
    }
    .more {
      font-size: 12px;
      font-weight: 500;
      color: #2a599f;
    }
  }
  .huodong_cont {
    padding: 0px 27px 40px 27px;
    display: flex;
    overflow-y: hidden;
    overflow-x: auto;

    /* 滚动条样式 */
    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 5px;
    }
    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 5px;
      box-shadow: inset 0 0 5px rgba(20, 72, 8, 0.3);
      background: #2a599f;
    }
    &::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      // box-shadow: inset 0 0 5px rgba(255, 0, 0, 0.2);
      border-radius: 5px;
      background: rgba(170, 170, 170, 0.27);
    }
    .huodong_box {
      width: 182px;
      min-width: 182px;
      // height: 121px;
      background: #ffffff;
      box-shadow: 0px 10px 20px 1px rgba(42, 89, 159, 0.1);
      border-radius: 12px 12px 12px 12px;
      margin-right: 15px;
      padding: 15px 10px;
      .title {
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        line-height: 1.4;
        margin-bottom: 15px;
      }
      .time {
        font-size: 10px;
        font-weight: 400;
        color: #7e7e7e;
        line-height: 1.4;
      }
      .didian {
        font-size: 10px;
        margin-top: 35px;
        line-height: 1.4;
        span {
          font-size: 12px;
        }
        font-size: 10px;
        font-weight: 400;
        color: #000000;
      }
    }
  }
  .rukou_cont {
    display: flex;
    justify-content: space-between;
    img {
      width: 100%;
    }
    .left_box {
      width: 155px;
    }
    .right_box {
      width: 155px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
.shousuo {
  padding: 0 27px;
  margin-bottom: 40px;
  .van-cell {
    height: 38px;
    font-size: 12px;
    line-height: 30px;
    .van-field__left-icon .van-icon,
    .van-field__right-icon .van-icon {
      font-size: 12px !important;
    }
  }
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
.tan_box {
  width: 100%;
  padding: 0 30px;

  img {
    width: 100%;
  }
}
.off {
  width: 35px;
  height: 35px;
  img {
    width: 100%;
  }
}
.kaiping {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(360deg, #f10430 0%, #f2441f 100%);
  z-index: 999;
  overflow: hidden;
  .k1 {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
  .k2_box {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    bottom: 36%;
    left: 0;
    .jiantou {
      position: absolute;
      width: 73px;
      top: calc(100% + 10px);
    }
  }
  .k2 {
    width: 65%;
  }
  .k3 {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
  }
}
.kaiping img {
  width: 100%;
}
.van-swipe-item {
  padding: 0 27px;
}
</style>
<style scoped>
.d1 {
  transition: ev_cont_bg 1s .5s ease both;
  -webkit-animation: ev_cont_bg 1s  .5s ease both;
  -moz-animation: ev_cont_bg 1s  .5s ease both;
  opacity: 0;
}
.d3 {
  transition: fadeInUp 1s 1s ease both;
  -webkit-animation: fadeInUp 1s 1s ease both;
  -moz-animation: fadeInUp 1s 1s ease both;
  opacity: 0;
}
.d2{
  transition: bounceIn 1s 1.5s ease both;
  -webkit-animation: bounceIn 1s 1.5s ease both;
  -moz-animation: bounceIn 1s 1.5s ease both;
}
.d4{
  transition: fadeInLeft 1s 2s ease both;
  -webkit-animation: fadeInLeft 1s 2s ease both;
  -moz-animation: fadeInLeft 1s 2s ease both;
}
@keyframes ev_cont_bg {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes bounceIn {

0%,
20%,
40%,
60%,
80%,
to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1)
}

0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3)
}

20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1)
}

40% {
    -webkit-transform: scale3d(.9, .9, .9);
    transform: scale3d(.9, .9, .9)
}

60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03)
}

80% {
    -webkit-transform: scale3d(.97, .97, .97);
    transform: scale3d(.97, .97, .97)
}

to {
    opacity: 1;
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
}
}
</style>